
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
  font: inherit;
}

body {
  color: #959595;
  font-weight: 500;
  background: #121212;
  overflow-x: hidden;
  line-height: normal;
}

img,
picture,
svg,
video {
  display: block;
  max-width: 100%;
}

*:not([class*='icon']) {
  font-family: 'Lato', sans-serif !important;
}

button:focus-visible,
a:focus-visible {
  outline: 1px auto #39CA88;
}

.App {
  overflow: hidden;
}


.burger {
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  transition: all .5s ease;
}
.burger-strip {
  transition: all .5s ease;
}
.strip div {
  height: 5px;
  border-radius: 2px;
  background: #FFF;
  margin: 8px;
  transition: all .55s cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 38px;
}
.burger:hover .strip div {
  background: white;
}

.burger-active .burger-strip-2 div:first-child {
  transform: translateY(16px) rotate(45deg);
}
.burger-active .burger-strip-2 div:nth-child(2) {
  opacity: 0;
}
.burger-active .burger-strip-2 div:last-child {
  transform: translateY(-10px) rotate(-45deg);
}

@media screen and (max-width: 600px) {
  .burger {
    width: 28px;
    height: 28px;
  }

  .strip div {
    height: 2px;
    margin: 5px;;
    width: 21px;
  }

  .burger-active .burger-strip-2 div:first-child {
    transform: translateY(9px) rotate(45deg);
  }
  .burger-active .burger-strip-2 div:last-child {
    transform: translateY(-5px) rotate(-45deg);
  }
}